import {useTranslations} from "next-intl";
import Image from "next/image";
import {HeaderButton} from "@/components/header/HeaderButton";
import MobileMegaMenuWrapper from "@/components/mega-menu/MobileMegaMenuWrapper";
import {motion, useScroll, useTransform} from "framer-motion";
import {useEffect, useState} from "react";
import LanguageMenuWrapper from "@/components/mega-menu/language/LanguageMenuWrapper";
import {PromotionService} from "@/service/PromotionService";
import {Promotion} from "@/domain/Promotion";

export default function GenericMobileHeader({containerClassName, initialColor, mode}: GenericHeaderProps) {

	const promotionService = new PromotionService();
	const [solutionsPromotion, setSolutionsPromotion] = useState<Promotion | null>(null);
	const [industriesPromotion, setIndustriesPromotion] = useState<Promotion | null>(null);
	const [resourcesPromotion, setResourcesPromotion] = useState<Promotion | null>(null);
	const [companyPromotion, setCompanyPromotion] = useState<Promotion | null>(null);

	useEffect(() => {
		promotionService.getByName("solutions").then(setSolutionsPromotion);
		promotionService.getByName("industries").then(setIndustriesPromotion);
		promotionService.getByName("resources").then(setResourcesPromotion);
		promotionService.getByName("company").then(setCompanyPromotion);
	}, []);

	const t = useTranslations('');
	const {scrollYProgress} = useScroll({});
	const color = useTransform(scrollYProgress, [0, 0.001, 0.1], [initialColor, initialColor, "white"]);
	const [isTransparent, setIsTransparent] = useState(initialColor == "transparent");

	color.on("change", () => {
		switch (color.get()) {
			case initialColor:
				if (initialColor == "transparent") setIsTransparent(true);
				break;
			default:
				setIsTransparent(false);
				break;
		}
	})

	return (
		<motion.div
			style={{
				backgroundColor: color,
				transition: "background-color 0.2s linear"
			}} className={`w-full fixed items-center flex h-16 z-50 px-4 md:px-9 ${containerClassName}`}>
			<nav className={"flex justify-evenly w-full"}>
				<div className={"flex flex-1 items-center"}>
					<a href={"/"}>
						<Image

							src={(isTransparent && initialColor != "white") || (isTransparent) ? "/logo/Logo.svg" : "/logo/Logo_black.svg"}
							alt={"Formica Logo"} width={82}
							height={90} className={"h-4 md:h-7"}/>
					</a>
				</div>
				<nav className={"max-w-fit items-center flex gap-x-2"}>
					{LanguageMenuWrapper({
						items: [
							{
								title: t("header.languages.tr"),
								id: "tr",
								disableArrowIcon: true
							},
							{
								title: t("header.languages.en"),
								id: "en",
								disableArrowIcon: true
							}/*,
							{
								title: t("header.languages.es"),
								id: "es",
								image: "/mega-menu-icon/language/es.png",
								activeImage: "/mega-menu-icon/language/es.png",
								disableArrowIcon: true
							},
							{
								title: t("header.languages.sa"),
								id: "sa",
								image: "/mega-menu-icon/language/sa.png",
								activeImage: "/mega-menu-icon/language/sa.png",
								disableArrowIcon: true
							}*/
						],
						containerClassName: "grid-cols-none",
						children: HeaderButton({
							icon: "language_button",
							className: "flex cursor-pointer fill-white",
							iconProps: {
								width: 16,
								height: 16
							},
							mode
						})
					})}
					{MobileMegaMenuWrapper({
						items: [
							{
								title: t("header.solutions.title"),
								description: t("header.solutions.description"),
								icon: "solutions",
								items: [
									{
										title: t("header.solutions.items.transactionMonitoring"),
										description: t("header.solutions.descriptions.transactionMonitoring"),
										href: "/solutions/transaction-monitoring",
										icon: "transaction-monitoring"
									},
									{
										title: t("header.solutions.items.accountProtection"),
										description: t("header.solutions.descriptions.accountProtection"),
										href: "/solutions/account-protection",
										icon: "account-protection"
									},
									{
										title: t("header.solutions.items.paymentOptimization"),
										description: t("header.solutions.descriptions.paymentOptimization"),
										href: "/solutions/payment-optimization",
										icon: "payment-optimization"
									},
									{
										title: t("header.solutions.items.scamPrevention"),
										description: t("header.solutions.descriptions.scamPrevention"),
										href: "/solutions/scam-prevention",
										icon: "scam-prevention"
									},
									{
										title: t("header.solutions.items.accountOpening"),
										description: t("header.solutions.descriptions.accountOpening"),
										href: "/solutions/account-opening",
										icon: "account-opening"
									},
									{
										title: t("header.solutions.items.amlKyc"),
										description: t("header.solutions.descriptions.amlKyc"),
										href: "/solutions/aml-kyc",
										icon: "aml-and-kyc"
									}
								],
								promotion: solutionsPromotion,
							},
							{
								title: t("header.industries.title"),
								description: t("header.industries.description"),
								icon: "industries",
								promotion: industriesPromotion,
								items: [
									{
										title: t("header.industries.items.banking"),
										href: "/industries/banking",
										description: t("header.industries.descriptions.banking"),
										icon: "banking"
									},
									{
										title: t("header.industries.items.travelAndTicketing"),
										href: "/industries/travel-and-ticketing",
										description: t("header.industries.descriptions.travelAndTicketing"),
										icon: "travel-and-ticketing"
									},
									{
										title: t("header.industries.items.paymentFacilitator"),
										href: "/industries/payment-facilitator",
										description: t("header.industries.descriptions.paymentFacilitator"),
										icon: "payment-faciliator"
									},
									{
										title: t("header.industries.items.bnpl"),
										href: "/industries/bnpl",
										description: t("header.industries.descriptions.bnpl"),
										icon: "bnpl"
									},
									{
										title: t("header.industries.items.eCommerce"),
										href: "/industries/e-commerce",
										description: t("header.industries.descriptions.eCommerce"),
										icon: "e-commerce"
									}
								]
							},
							{
								title: t("header.resources.title"),
								description: t("header.resources.description"),
								icon: "resources",
								promotion: resourcesPromotion,
								items: [
									{
										title: t("header.resources.items.blogs"),
										href: "/blog",
										icon: "blogs_mobile_icon",
									},
									{
										title: t("header.resources.items.eBooks"),
										href: "/e-books",
										icon: "ebooks_mobile_icon",
									}
								]
							},
							{
								title: t("header.company.title"),
								description: t("header.company.description"),
								icon: "company",
								promotion: companyPromotion,
								items: [
									{
										title: t("header.company.items.aboutUs"),
										href: "/company/about-us",
										icon: "about-us",
										description: t("header.company.descriptions.aboutUs")
									},
									{
										title: t("header.company.items.press"),
										href: "/company/press",
										icon: "pressandmedia",
										description: t("header.company.descriptions.press")
									},
									{
										title: t("header.company.items.references"),
										href: "/company/references",
										icon: "ourreferences",
										description: t("header.company.descriptions.references")
									}
								]
							}
						],
						promotion: industriesPromotion,
						children: HeaderButton({
							icon: "menu",
							className: "flex cursor-pointer fill-white",
							iconProps: {
								width: 16,
								height: 16
							},
							mode
						})
					})}
				</nav>
			</nav>
		</motion.div>
	);
}

export interface GenericHeaderProps {
	containerClassName?: string;
	initialColor?: "transparent" | "white";
	mode?: "light" | "dark";
}
