import Icon from "@/components/icon/Icon";
import classNames from "classnames";
import {Promotion} from "@/domain/Promotion";

export default function MegaMenuItem({
	                                     icon,
	                                     title,
	                                     description,
	                                     href,
	                                     disableArrowIcon,
	                                     containerClassName
                                     }: MegaMenuItemProps) {

	return (
		<a
			className={classNames("flex flex-row min-w-[295px] p-2 cursor-pointer items-center justify-between hover:bg-whitesmoke", containerClassName)}
			href={href} key={title}>
			<div className={"flex flex-row items-start gap-x-3 gap-y-0.5"}>
				{icon &&
					<p className={"w-5 fill-formica text-formica"}>
					<Icon icon={icon} width={20}/>
					</p>}
				{(title || description) && <div>
					{title && <p className={"font-medium text-text_dark text-base capitalize"}>
						{title}
					</p>}
					{description && <p className={"font-light text-text_light text-sm"}>
						{description}
					</p>}
				</div>}
			</div>
		</a>
	)
}

export interface MegaMenuItemProps {
	icon?: string
	title?: string
	description?: string
	href?: string
	disableArrowIcon?: boolean
	items?: Array<MegaMenuItemProps>
	selectedItem?: string | null
	onSelect?(title: string | null): void
	containerClassName?: string
	promotion?: Promotion
}
