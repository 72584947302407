"use client"
import {ReactElement, useEffect, useRef} from "react";
import classNames from "classnames";
import LanguageItem, {LanguageItemProps} from "@/components/mega-menu/language/LanguageItemV2";

export default function LanguageMenuWrapper({title, containerClassName, items, children}: LanguageMenuWrapperProps) {
	const wrapperRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		function handleClickOutside() {
			if (wrapperRef.current) {
				wrapperRef.current.classList.remove("group-hover:scale-100");
			}
		}

		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, []);

	return (
		<div  className={"group relative flex"}>
			{children}
			<div
				ref={wrapperRef}
				onMouseEnter={(e) => {
					e.currentTarget.classList.remove("group-hover:scale-100")
				}}
				onMouseLeave={(e) => {
					e.currentTarget.classList.add("group-hover:scale-100")
				}}
				className={"group/bg absolute w-[400vw] h-[100vh] -ml-[100vw] z-10 top-16 rounded-b-xl scale-0 group-hover:scale-100"}/>
			<div
				className={classNames("absolute z-20 -left-36 xl:left-0 top-16 scale-0 group-hover:scale-100 grid grid-cols-2 min-w-max", containerClassName)}>
				<div className={"bg-transparent p-0.5"}/>
				{title && <div className={"font-medium text-formica text-lg px-3 w-full col-span-full"}>
					{title}
				</div>}
				<div className={"bg-white rounded-lg"}>
					{items.map((item, index) => LanguageItem({...item}))}
				</div>
			</div>
		</div>
	)
}

interface LanguageMenuWrapperProps {
	title?: string
	items: Array<LanguageItemProps>
	children: ReactElement
	containerClassName?: string
	mode?: "light" | "dark"
}
